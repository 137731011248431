import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import ServiciosButton from "./widgets/ServiciosButton";
import ServiciosOptions from "./widgets/ServiciosOptions";
import CategoriaOptions from "./widgets/CategoriaOptions";

const config = {
  initialMessages: [
    createChatBotMessage("Bienvenido al chatbot de servicios legales. ¿En qué puedo ayudarte?", {
      widget: "serviciosButton",
    }),
  ],
  widgets: [
    {
      widgetName: "serviciosButton",
      widgetFunc: (props) => <ServiciosButton {...props} />,
    },
    {
      widgetName: "serviciosOptions",
      widgetFunc: (props) => <ServiciosOptions actionProvider={props.actionProvider} />,
    },
    {
      widgetName: "procesosCiviles",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="procesosCiviles" />,
    },
    {
      widgetName: "procesosLaborales",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="procesosLaborales" />,
    },
    {
      widgetName: "procesosPenales",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="procesosPenales" />,
    },
    {
      widgetName: "serviciosNotariales",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="serviciosNotariales" />,
    },
    {
      widgetName: "asuntosAdministrativos",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="asuntosAdministrativos" />,
    },
    {
      widgetName: "derechoFamiliar",
      widgetFunc: (props) => <CategoriaOptions {...props} categoria="derechoFamiliar" />,
    },
  ],
};

export default config;