// src/styles/sharedStyles.js

import React from 'react';

export const colors = {
    primary: 'bg-gray-800 dark:bg-gray-700',
    secondary: 'bg-gray-700 dark:bg-gray-600',
    accent: 'text-red-700 dark:text-red-500',
    text: 'text-gray-800 dark:text-gray-200',
    background: 'bg-gray-100 dark:bg-gray-900',
    white: 'bg-white dark:bg-gray-800',
};

export const typography = {
  sans: 'font-serif',
  serif: 'font-serif',
};

export const shadows = {
  sm: 'shadow-sm',
  md: 'shadow-md',
  lg: 'shadow-lg',
};

export const Button = ({ children, primary = false, className = '', ...props }) => (
  <button
    className={`px-6 py-2 rounded-sm font-bold transition duration-300 ${
      primary
        ? `${colors.primary} text-white hover:bg-gray-700`
        : `${colors.white} ${colors.text} border border-gray-800 hover:bg-gray-800 hover:text-white`
    } ${className}`}
    {...props}
  >
    {children}
  </button>
);

export const Card = ({ children, className = '', ...props }) => (
  <div
    className={`${colors.white} rounded-sm ${shadows.md} p-6 border border-gray-200 ${className}`}
    {...props}
  >
    {children}
  </div>
);

export const chatbotStyles = {
  widget: 'fixed bottom-5 right-5 z-50',
  container: 'w-80 bg-white rounded-lg shadow-lg overflow-hidden flex flex-col',
  header: 'bg-gray-800 text-white p-3 flex justify-between items-center',
  closeButton: 'text-white text-2xl font-bold cursor-pointer',
  toggler: 'bg-gray-800 text-white px-4 py-2 rounded-sm shadow-lg hover:bg-gray-700 transition duration-300',
  optionsContainer: 'flex flex-wrap justify-center p-3',
  optionButton: `m-1 py-2 px-4 ${colors.primary} text-white border-none rounded-sm cursor-pointer hover:bg-gray-700 transition duration-300`,
  chatContainer: 'flex-grow',
  inputContainer: 'p-3 border-t border-gray-200',
};

export const LegalSection = ({ children, className = '', ...props }) => (
  <section
    className={`${colors.background} ${typography.serif} py-12 ${className}`}
    {...props}
  >
    {children}
  </section>
);

export const chatbotCustomScrollbar = `
  .react-chatbot-kit-chat-message-container::-webkit-scrollbar {
    width: 6px;
  }

  .react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  .react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;