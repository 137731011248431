import keywordMapping from '../../data/keywordMapping';

class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const lowerCaseMessage = message.toLowerCase();

    if (lowerCaseMessage.includes("servicios")) {
      return this.actionProvider.handleServicios();
    }

    const matchedQuestion = this.findBestMatch(lowerCaseMessage);
    if (matchedQuestion) {
      return this.actionProvider.handleCommonQuestion(matchedQuestion.category, matchedQuestion.question, matchedQuestion.answer);
    }

    return this.actionProvider.handleDefault();
  }

  findBestMatch(message) {
    const words = message.split(/\s+/);
    let bestMatch = null;
    let highestScore = 0;

    keywordMapping.forEach(qa => {
      const score = qa.keywords.filter(keyword => words.includes(keyword)).length;
      if (score > highestScore) {
        highestScore = score;
        bestMatch = qa;
      }
    });

    return highestScore > 0 ? bestMatch : null;
  }
}

export default MessageParser;