import React, { useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ThemeProvider } from './contexts/ThemeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import ChatbotWidget from './components/ChatbotWidget';
import ScrollToTop from './components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import { MouseProvider } from './contexts/MouseContext';
import "./components/chatbot/ChatbotStyles.css";
import GoogleAnalytics from './GoogleAnalytics';
import ConsentBanner from './components/ConsentBanner';

// Lazy loading for components (optional)
const Home = lazy(() => import('./pages/Home'));
const Services = lazy(() => import('./pages/Services'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));

const PageWrapper = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <MouseProvider>
          <Router>
            <GoogleAnalytics />
            <div className="flex flex-col min-h-screen transition-colors duration-300 dark:bg-gray-900 dark:text-white">
              <a href="#main-content" className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 focus:z-50 focus:bg-white focus:text-black focus:p-4">
                Skip to main content
              </a>
              <header>
                <Header />
              </header>
              <main id="main-content" className="flex-grow">
                <PageWrapper>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/servicios" element={<Services />} />
                      <Route path="/sobre-nosotros" element={<About />} />
                      <Route path="/contacto" element={<Contact />} />
                    </Routes>
                  </Suspense>
                </PageWrapper>
              </main>
              <footer>
                <Footer />
              </footer>
              <ChatbotWidget />
              <ScrollToTop />
              <ConsentBanner />
            </div>
          </Router>
        </MouseProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;