import React, { useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './chatbot/config';
import MessageParser from './chatbot/MessageParser';
import ActionProvider from './chatbot/ActionProvider';
import { chatbotStyles } from '../styles/sharedStyles';

// src/components/ChatbotWidget.js

function ChatbotWidget() {
  const [showBot, setShowBot] = useState(false);

  const toggleBot = () => {
    setShowBot(!showBot);
  };

  return (
    <>
      <div className={`fixed bottom-4 right-4 z-40 ${showBot ? 'mb-16' : ''}`}>
        {showBot && (
          <div className="mb-4">
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
            <button onClick={toggleBot} className={chatbotStyles.closeButton}>
              Cerrar Chat
            </button>
          </div>
        )}
        {!showBot && (
          <button onClick={toggleBot} className={chatbotStyles.toggler}>
            Abrir Chat
          </button>
        )}
      </div>
    </>
  );
}

export default ChatbotWidget;
