import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const ConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { darkMode } = useTheme();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted'
    });
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBanner(false);
    window.gtag('consent', 'update', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied'
    });
  };

  if (!showBanner) return null;

  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={`fixed bottom-0 left-0 right-0 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-4 shadow-lg z-50`}
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <p className="text-sm mb-4 md:mb-0 md:mr-4">
          Utilizamos cookies para mejorar su experiencia en nuestro sitio web. Al continuar navegando, acepta nuestra política de cookies.
        </p>
        <div className="flex space-x-4">
          <button
            onClick={handleAccept}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition duration-300"
          >
            Aceptar
          </button>
          <button
            onClick={handleReject}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded transition duration-300"
          >
            Rechazar
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ConsentBanner;