import React from "react";

const ServiciosButton = (props) => {
  const handleClick = () => {
    props.actionProvider.handleServicios();
  };

  return <button onClick={handleClick}>Servicios</button>;
};

export default ServiciosButton;