import React from "react";
import "../ChatbotStyles.css";

const CategoriaOptions = (props) => {
  const categorias = {
    procesosCiviles: [
      { text: "Proceso ordinario", costo: "20% hasta ₡16,500,000, 15% sobre el exceso hasta ₡82,500,000, 10% sobre el exceso de ₡82,500,000" },
      { text: "Proceso monitorio", costo: "50% de la Tarifa General" },
      { text: "Proceso de ejecución", costo: "50% de la Tarifa General, mínimo ₡60,500" },
      { text: "Medidas cautelares", costo: "Mínimo ₡121,000" },
      { text: "¿Qué debo hacer si he sido demandado?", costo: "Depende del tipo de demanda, consulta gratuita" },
      { text: "¿Puedo resolver mi caso fuera de la corte?", costo: "Depende del caso, consulta gratuita" },
      { text: "¿Qué es la mediación?", costo: "Consulta gratuita" },
      { text: "¿Qué es un proceso ejecutivo?", costo: "Depende del monto, consulta gratuita" },
    ],
    procesosLaborales: [
      { text: "Conflictos individuales", costo: "25% al 30% del importe de la condenatoria o absolutoria, mínimo ₡121,000" },
      { text: "Riesgos del trabajo", costo: "20% al 25% del monto de la indemnización o absolución, mínimo ₡121,000" },
      { text: "Procesos colectivos", costo: "Mínimo ₡423,500 para conflictos colectivos y convenciones colectivas" },
      { text: "Lesiones en el trabajo", costo: "20% al 25% del monto de la indemnización, mínimo ₡121,000" },
      { text: "Despido injustificado", costo: "25% al 30% del importe de la condenatoria, mínimo ₡121,000" },
      { text: "¿Qué es un preaviso?", costo: "Consulta gratuita" },
      { text: "¿Cómo se calculan las horas extra?", costo: "Consulta gratuita" },
    ],
    procesosPenales: [
      { text: "Procesos penales", costo: "Mínimo ₡242,000" },
      { text: "Recursos de revisión o casación", costo: "Mínimo ₡605,000" },
      { text: "Procesos de tránsito", costo: "Mínimo ₡192,500 con juicio oral, ₡121,000 sin juicio oral" },
      { text: "Ayuda para salir de la cárcel", costo: "Mínimo ₡242,000" },
      { text: "Accidente de tránsito", costo: "Mínimo ₡192,500 con juicio oral, ₡121,000 sin juicio oral" },
      { text: "¿Qué es la libertad condicional?", costo: "Consulta gratuita" },
      { text: "¿Qué es un recurso de casación?", costo: "Mínimo ₡605,000" },
    ],
    serviciosNotariales: [
      { text: "Instrumento público", costo: "Mínimo ₡60,500" },
      { text: "Constitución de sociedades especiales", costo: "Mínimo ₡363,000" },
      { text: "Actas notariales", costo: "Mínimo ₡60,500 en notaría, ₡121,000 fuera de oficina" },
      { text: "Autenticación de firmas", costo: "Mínimo ₡18,150 por firma" },
      { text: "Constitución de S.A.", costo: "Mínimo ₡363,000" },
      { text: "¿Qué es un poder especial?", costo: "Consulta gratuita" },
      { text: "Legalización de documento extranjero", costo: "Depende del documento, consulta gratuita" },
    ],
    asuntosAdministrativos: [
      { text: "Trámites migratorios", costo: "Desde ₡121,000 hasta ₡605,000 según el tipo de trámite" },
      { text: "Licitaciones y contrataciones", costo: "Mínimo ₡302,500" },
      { text: "Propiedad industrial", costo: "Desde ₡181,500 hasta ₡484,000 según el tipo de trámite" },
      { text: "Patente de negocio", costo: "Depende del tipo de negocio, consulta gratuita" },
      { text: "Residencia para extranjeros", costo: "Desde ₡121,000 hasta ₡605,000 según el tipo de trámite" },
      { text: "Permiso de construcción", costo: "Depende del proyecto, consulta gratuita" },
      { text: "¿Qué es un recurso de amparo?", costo: "Consulta gratuita" },
    ],
    derechoFamiliar: [
      { text: "Divorcio por mutuo acuerdo", costo: "Mínimo ₡121,000 sin gananciales, 50% de la Tarifa General con gananciales (mínimo ₡181,500)" },
      { text: "Procesos de pensión alimentaria", costo: "Mínimo ₡181,500" },
      { text: "Capitulaciones matrimoniales", costo: "Mínimo ₡121,000" },
      { text: "Pensión para padres", costo: "Mínimo ₡181,500" },
      { text: "Cobro de pensión alimentaria", costo: "Mínimo ₡181,500" },
      { text: "Custodia compartida", costo: "Depende del caso, consulta gratuita" },
      { text: "¿Qué es la unión de hecho?", costo: "Consulta gratuita" },
    ],
  };

  const options = categorias[props.categoria].map((option) => (
    <button
      key={option.text}
      className="categoria-option"
      onClick={() => props.actionProvider.handleServicioSeleccionado(option.text, option.costo)}
    >
      {option.text}
    </button>
  ));

  const backButton = (
    <button key="back" className="back-button" onClick={() => props.actionProvider.handleServicios()}>
      Volver a categorías
    </button>
  );

  return (
    <div className="categoria-options-container">
      <div className="options-grid">{options}</div>
      {backButton}
    </div>
  );
};

export default CategoriaOptions;