class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleWelcome = () => {
    const message = this.createChatBotMessage(
      "Bienvenido al chatbot de servicios legales. ¿En qué puedo ayudarte?",
      {
        widget: "serviciosButton",
      }
    );
    this.updateChatbotState(message);
  };

  handleServicios = () => {
    const message = this.createChatBotMessage(
      "Por favor, selecciona una categoría de servicios:",
      {
        widget: "serviciosOptions",
      }
    );
    this.updateChatbotState(message);
  };

  handleCategoriaSeleccionada = (categoria) => {
    const message = this.createChatBotMessage(
      `Has seleccionado la categoría ${categoria}. Por favor, elige un servicio específico:`,
      {
        widget: categoria,
      }
    );
    this.updateChatbotState(message);
  };

  handleServicioSeleccionado = (servicio, costo) => {
    const message = this.createChatBotMessage(
      <div className="servicio-seleccionado">
        <h3>Servicio seleccionado: {servicio}</h3>
        <p>Costo aproximado: {costo}</p>
        <p>¿Necesitas más información sobre este servicio o deseas consultar sobre otro?</p>
      </div>,
      {
        widget: "serviciosButton",
      }
    );
    this.updateChatbotState(message);
  };

  handleCommonQuestion = (category, question, answer) => {
    const message = this.createChatBotMessage(
      <div>
        <p><strong>Categoría:</strong> {category}</p>
        <p><strong>Pregunta:</strong> {question}</p>
        <p><strong>Respuesta:</strong> {answer}</p>
      </div>
    );
    this.updateChatbotState(message);
  };

  handleDefault = () => {
    const message = this.createChatBotMessage(
      "Lo siento, no entendí tu pregunta. ¿Podrías reformularla o elegir una de nuestras categorías de servicios?",
      {
        widget: "serviciosButton",
      }
    );
    this.updateChatbotState(message);
  };

  updateChatbotState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}

export default ActionProvider;