const commonLegalQuestions = [
  {
    category: "General",
    questions: [
      {
        question: "¿Cuánto cuesta una consulta inicial?",
        answer: "Ofrecemos una breve consulta inicial gratuita para evaluar su caso. Los costos posteriores dependerán de la complejidad de su situación legal.",
        keywords: ["costo", "consulta", "inicial", "gratis", "precio"]
      },
      {
        question: "¿Cuánto tiempo tomará resolver mi caso?",
        answer: "El tiempo para resolver un caso varía según su complejidad. Durante la consulta inicial, podremos darle una estimación más precisa basada en los detalles de su situación.",
        keywords: ["tiempo", "duración", "resolver", "caso"]
      },
      {
        question: "¿Qué documentos necesito para mi primera cita?",
        answer: "Es útil traer cualquier documento relacionado con su caso, como contratos, correspondencia, informes policiales o médicos, según corresponda a su situación.",
        keywords: ["documentos", "primera", "cita", "papeles"]
      },
      {
        question: "¿Puedo cambiar de abogado si ya tengo uno?",
        answer: "Sí, tiene derecho a cambiar de abogado. Sin embargo, es importante considerar las implicaciones contractuales y éticas antes de hacerlo.",
        keywords: ["cambiar", "abogado"]
      },
      {
        question: "¿Cuáles son sus áreas de especialización?",
        answer: "Nos especializamos en derecho corporativo, litigios, derecho laboral y propiedad intelectual. Para más detalles sobre nuestras áreas de práctica, visite nuestra página de servicios.",
        keywords: ["áreas", "especialización", "práctica"]
      },
      {
        question: "¿Ofrecen servicios de representación en corte?",
        answer: "Sí, ofrecemos servicios de representación en corte para diversos tipos de casos legales.",
        keywords: ["representación", "corte", "juicio"]
      },
      {
        question: "¿Cómo se manejan los honorarios legales?",
        answer: "Nuestros honorarios se basan en varios factores, incluyendo la complejidad del caso y el tiempo requerido. Discutimos y acordamos los honorarios con nuestros clientes antes de comenzar cualquier trabajo.",
        keywords: ["honorarios", "costos", "tarifas"]
      },
      {
        question: "¿Ofrecen servicios en línea o remotos?",
        answer: "Sí, ofrecemos consultas en línea y servicios remotos para la mayoría de nuestras áreas de práctica. Esto incluye videoconferencias, intercambio seguro de documentos y firmas electrónicas cuando sea posible.",
        keywords: ["en línea", "remoto", "virtual", "videoconferencia"]
      },
      {
        question: "¿Cómo puedo programar una cita?",
        answer: "Puede programar una cita llamando a nuestro número principal, enviando un correo electrónico o utilizando el formulario de contacto en nuestro sitio web. Nos esforzamos por responder a todas las solicitudes dentro de un día hábil.",
        keywords: ["cita", "programar", "agendar", "contacto"]
      }
    ]
  },
  {
    category: "Procesos Civiles",
    questions: [
      {
        question: "¿Qué debo hacer si he sido demandado?",
        answer: "Si ha sido demandado, es crucial que busque asesoría legal de inmediato. No ignore la demanda, ya que esto puede resultar en un fallo en su contra por incomparecencia.",
        keywords: ["demandado", "demanda", "qué hacer"]
      },
      {
        question: "¿Puedo resolver mi caso fuera de la corte?",
        answer: "Muchos casos pueden resolverse fuera de la corte a través de negociaciones o métodos alternativos de resolución de disputas. Evaluaremos su caso y le recomendaremos la mejor estrategia.",
        keywords: ["resolver", "fuera", "corte", "negociación"]
      },
      {
        question: "¿Qué es la mediación y cuándo se utiliza?",
        answer: "La mediación es un proceso de resolución de conflictos donde un tercero neutral ayuda a las partes a llegar a un acuerdo. Se utiliza a menudo en disputas civiles, familiares y laborales como alternativa al litigio.",
        keywords: ["mediación", "resolución", "conflictos"]
      },
      {
        question: "¿Qué es un proceso ordinario y cuánto puede costar?",
        answer: "Un proceso ordinario es un tipo de juicio civil para casos complejos. El costo varía según el monto en disputa, pero generalmente es un 20% hasta ₡16,500,000, 15% sobre el exceso hasta ₡82,500,000, y 10% sobre el exceso de ₡82,500,000. Podemos revisar su caso específico para darle un estimado más preciso.",
        keywords: ["proceso", "ordinario", "costo", "juicio", "civil"]
      },
      {
        question: "¿Qué es una medida cautelar y cuándo se puede usar?",
        answer: "Una medida cautelar es una acción legal para proteger sus derechos antes o durante un juicio. Se usa cuando hay riesgo de que la otra parte haga algo que pueda perjudicarle. El costo mínimo es de ₡121,000, pero depende de la complejidad del caso. Cuéntenos su situación y le asesoramos si es necesario aplicar una.",
        keywords: ["medida", "cautelar", "proteger", "derechos"]
      },
      {
        question: "Tengo un conflicto con mi vecino por una cerca, ¿qué puedo hacer?",
        answer: "Eso suena como un conflicto de propiedad, que entraría en la categoría de procesos civiles. Dependiendo de la complejidad, podríamos manejarlo como un proceso ordinario o buscar una mediación. Los costos varían, pero podemos discutirlo en detalle cuando nos cuente más sobre la situación.",
        keywords: ["vecino", "cerca", "conflicto", "propiedad"]
      },
      {
        question: "¿Qué es un proceso ejecutivo y cuándo se utiliza?",
        answer: "Un proceso ejecutivo es un procedimiento legal utilizado para cobrar deudas respaldadas por documentos que prueben la existencia de la obligación. Se utiliza comúnmente en casos de cheques sin fondos, pagarés vencidos o contratos de préstamo incumplidos.",
        keywords: ["ejecutivo", "cobro", "deuda", "obligación"]
      },
      {
        question: "¿Cuánto tiempo toma resolver un proceso civil típico?",
        answer: "La duración de un proceso civil puede variar significativamente dependiendo de la complejidad del caso y la carga de trabajo del tribunal. En general, un proceso ordinario puede tomar de 1 a 3 años, mientras que un proceso ejecutivo puede resolverse en 6 meses a 1 año.",
        keywords: ["duración", "tiempo", "resolver", "proceso civil"]
      },
      {
        question: "¿Qué es un embargo preventivo y cuándo se puede solicitar?",
        answer: "Un embargo preventivo es una medida cautelar que se utiliza para asegurar bienes del deudor antes de que se dicte una sentencia. Se puede solicitar cuando existe el riesgo de que el deudor oculte o disponga de sus bienes para evitar el pago de una deuda.",
        keywords: ["embargo", "preventivo", "medida", "cautelar", "deudor", "bienes"]
      },
      {
        question: "¿Cómo puedo impugnar un contrato que firmé bajo presión?",
        answer: "Si firmó un contrato bajo presión o coacción, puede solicitar su anulación por vicio del consentimiento. Es importante recopilar pruebas que demuestren la presión ejercida. Podemos ayudarle a presentar una demanda de nulidad de contrato ante el juzgado correspondiente.",
        keywords: ["impugnar", "contrato", "presión", "coacción", "anulación", "vicio", "consentimiento"]
      }
    ]
  },
  {
    category: "Procesos Laborales",
    questions: [
      {
        question: "Me lesioné en el trabajo, ¿cómo puedo solicitar una indemnización?",
        answer: "Eso entra en la categoría de riesgos del trabajo. Podemos ayudarle a presentar una demanda para obtener la indemnización que merece. Nuestros honorarios serían del 20% al 25% del monto de la indemnización, con un mínimo de ₡121,000. Pero primero, revisemos su caso para ver cómo podemos ayudarle mejor.",
        keywords: ["lesión", "trabajo", "indemnización", "riesgos"]
      },
      {
        question: "Mi empleador me despidió sin justificación, ¿qué puedo hacer?",
        answer: "Si lo despidieron sin justificación, tiene derecho a recibir sus prestaciones laborales. Podemos ayudarle a calcular lo que le corresponde y, si es necesario, presentar una demanda laboral. Es importante actuar rápido, así que no dude en contactarnos para revisar su caso lo antes posible.",
        keywords: ["despido", "injustificado", "empleador", "prestaciones"]
      },
      {
        question: "¿Qué es un preaviso y cuándo se aplica?",
        answer: "El preaviso es un período de notificación que tanto el empleador como el trabajador deben dar antes de terminar la relación laboral. La duración del preaviso depende del tiempo que el empleado ha trabajado en la empresa. Es una protección legal para ambas partes.",
        keywords: ["preaviso", "notificación", "terminar", "relación laboral"]
      },
      {
        question: "¿Qué son las horas extra y cómo se calculan?",
        answer: "Las horas extra son las que se trabajan más allá de la jornada ordinaria. En Costa Rica, se pagan a tiempo y medio del salario regular. Se calculan sobre el salario ordinario por hora y se deben pagar por cada hora o fracción de hora extra trabajada.",
        keywords: ["horas extra", "cálculo", "pago", "jornada"]
      },
      {
        question: "¿Qué es el aguinaldo y cómo se calcula?",
        answer: "El aguinaldo es un beneficio anual obligatorio equivalente a un mes de salario. Se calcula sumando los salarios ordinarios y extraordinarios recibidos del 1 de diciembre al 30 de noviembre del año siguiente, dividido entre 12. Debe pagarse dentro de los primeros 20 días de diciembre.",
        keywords: ["aguinaldo", "cálculo", "beneficio", "anual", "salario"]
      },
      {
        question: "¿Qué son las vacaciones profilácticas y quién tiene derecho a ellas?",
        answer: "Las vacaciones profilácticas son días adicionales de descanso que se otorgan a trabajadores expuestos a sustancias tóxicas o condiciones especialmente difíciles. El derecho a estas vacaciones y su duración dependen del tipo de trabajo y están reguladas por ley o convenio colectivo.",
        keywords: ["vacaciones", "profilácticas", "descanso", "adicional", "tóxico", "condiciones"]
      }
    ]
  },
  {
    category: "Procesos Penales",
    questions: [
      {
        question: "Un conocido está en la cárcel, ¿cómo puedo ayudarlo a salir?",
        answer: "Para casos penales, como sacar a alguien de la cárcel (o del 'tabo', como se dice coloquialmente), nuestro costo mínimo es de ₡242,000. Pero cada caso es único. Necesitamos más detalles sobre la situación de su conocido para determinar la mejor estrategia legal y darle un estimado más preciso de los costos.",
        keywords: ["cárcel", "tabo", "sacar", "penal"]
      },
      {
        question: "Tuve un accidente de tránsito. ¿Qué debo hacer?",
        answer: "Lo primero es asegurarse de que todos estén bien y llamar a las autoridades de tránsito. Luego, recopile toda la información posible: fotos, datos del otro conductor, testigos. Nosotros podemos ayudarle a manejar el caso con el seguro y, si es necesario, presentar una demanda para que le indemnicen por los daños.",
        keywords: ["accidente", "tránsito", "choque", "indemnización"]
      },
      {
        question: "¿Qué es la libertad condicional y cómo se obtiene?",
        answer: "La libertad condicional es un beneficio que permite a un condenado cumplir el resto de su pena fuera de prisión, bajo ciertas condiciones. Se puede solicitar después de cumplir una parte de la condena y depende de factores como la conducta del reo y la naturaleza del delito.",
        keywords: ["libertad condicional", "beneficio", "condena", "prisión"]
      },
      {
        question: "¿Qué es un recurso de casación en materia penal?",
        answer: "Un recurso de casación es una apelación ante la Sala Tercera de la Corte Suprema de Justicia. Se utiliza para impugnar sentencias definitivas por errores en la aplicación de la ley o en el procedimiento. Es un recurso extraordinario y tiene requisitos específicos para su presentación.",
        keywords: ["casación", "recurso", "apelación", "Sala Tercera"]
      },
      {
        question: "¿Qué es la suspensión del proceso a prueba y cuándo se puede solicitar?",
        answer: "La suspensión del proceso a prueba, también conocida como 'probatoria', es una medida alternativa a la prisión. Se puede solicitar en delitos que permitan ejecución condicional de la pena, siempre que el imputado no tenga condenas anteriores. Implica cumplir ciertas condiciones durante un período determinado.",
        keywords: ["suspensión", "proceso", "prueba", "probatoria", "alternativa", "prisión"]
      },
      {
        question: "¿Qué es el principio de oportunidad en materia penal?",
        answer: "El principio de oportunidad permite al Ministerio Público no ejercer la acción penal en ciertos casos, como delitos de bagatela o cuando el imputado ha sufrido un daño físico o moral grave. Su aplicación busca descongestionar el sistema judicial y priorizar casos más graves.",
        keywords: ["principio", "oportunidad", "penal", "ministerio", "público", "acción"]
      }
    ]
  },
  {
    category: "Servicios Notariales",
    questions: [
      {
        question: "Quiero poner una S.A., ¿cuánto me cobra por los trámites?",
        answer: "Para constituir una sociedad especial como una S.A., nuestro costo mínimo es de ₡363,000. Esto incluye todos los trámites notariales necesarios. Podemos asesorarle sobre el tipo de sociedad que más le conviene y encargarnos de todo el papeleo para que usted se enfoque en su negocio.",
        keywords: ["sociedad", "anónima", "S.A.", "constituir", "empresa"]
      },
      {
        question: "¿Qué es un poder especial y cuándo se necesita?",
        answer: "Un poder especial es un documento notarial que autoriza a una persona a actuar en nombre de otra para un acto específico. Se necesita cuando quiere que alguien lo represente en una transacción particular, como la venta de un vehículo o la firma de un contrato específico.",
        keywords: ["poder especial", "autorización", "representación", "acto específico"]
      },
      {
        question: "¿Cómo se legaliza un documento extranjero en Costa Rica?",
        answer: "Para legalizar un documento extranjero en Costa Rica, generalmente se requiere la apostilla del país de origen si es parte del Convenio de La Haya. Si no, el documento debe ser autenticado por el consulado costarricense en el país de origen y luego por el Ministerio de Relaciones Exteriores en Costa Rica.",
        keywords: ["legalización", "documento extranjero", "apostilla", "autenticación"]
      },
      {
        question: "¿Qué es una escritura de compraventa y qué debe incluir?",
        answer: "Una escritura de compraventa es un documento notarial que formaliza la transferencia de propiedad de un bien inmueble. Debe incluir la identificación de las partes, descripción detallada del inmueble, precio y forma de pago, declaración de que la venta está libre de gravámenes, y las firmas de las partes ante el notario.",
        keywords: ["escritura", "compraventa", "notarial", "propiedad", "inmueble"]
      },
      {
        question: "¿Qué es un testamento abierto y cómo se hace?",
        answer: "Un testamento abierto es aquel que se otorga ante notario y testigos. El testador expresa su voluntad al notario, quien lo redacta según las disposiciones legales. Luego se lee en voz alta, se firma por el testador, testigos y notario. Es la forma más común de testamento en Costa Rica.",
        keywords: ["testamento", "abierto", "notario", "testigos", "voluntad"]
      }
    ]
  },
  {
    category: "Asuntos Administrativos",
    questions: [
      {
        question: "¿Cómo hago para sacar la patente de mi negocio?",
        answer: "Sacar una patente comercial entra en la categoría de asuntos administrativos. Podemos ayudarle con todos los trámites necesarios ante la municipalidad. El costo dependerá del tipo de negocio y la complejidad del proceso, pero estaremos encantados de darle un estimado después de conocer más detalles de su situación.",
        keywords: ["patente", "negocio", "comercial", "municipalidad"]
      },
      {
        question: "¿Cómo hago para sacar la residencia de mi doña que es extranjera?",
        answer: "El proceso de residencia para su esposa implica varios pasos. Necesitaremos documentos como el certificado de matrimonio, antecedentes penales de su esposa, y pruebas de su capacidad económica. Nosotros podemos encargarnos de todos los trámites con Migración para hacer el proceso más fácil y rápido.",
        keywords: ["residencia", "extranjera", "migración", "trámites"]
      },
      {
        question: "¿Qué es un recurso de revocatoria y cuándo se presenta?",
        answer: "Un recurso de revocatoria es una solicitud para que la misma autoridad que dictó un acto administrativo lo revise y modifique o anule. Se presenta cuando se considera que la decisión es incorrecta o perjudicial. Generalmente, debe interponerse dentro de los tres días hábiles siguientes a la notificación del acto.",
        keywords: ["recurso", "revocatoria", "administrativo", "autoridad", "modificar", "anular"]
      },
      {
        question: "¿Cómo se tramita una concesión de agua para uso agrícola?",
        answer: "Para tramitar una concesión de agua para uso agrícola, debe presentar una solicitud ante la Dirección de Agua del MINAE. Se requiere un estudio técnico que justifique el caudal solicitado, planos de la propiedad, y certificación de propiedad. El proceso incluye una inspección de campo y puede tomar varios meses.",
        keywords: ["concesión", "agua", "agrícola", "MINAE", "estudio", "técnico", "caudal"]
      }
    ]
  },
  {
    category: "Derecho Familiar",
    questions: [
      {
        question: "¿Cómo hago para sacar la pensión de mi tata?",
        answer: "Para solicitar la pensión de su padre, necesitamos revisar su caso específico. Generalmente, se requiere presentar documentos como la cédula, comprobantes de ingresos y gastos, y pruebas de la relación familiar. Podemos ayudarle con todo el proceso, ¡no se preocupe!",
        keywords: ["pensión", "padre", "tata", "solicitar"]
      },
      {
        question: "¿Cómo hago para que mi ex me pase la plata de la pensión?",
        answer: "Si su ex no está cumpliendo con la pensión alimentaria, podemos ayudarle a presentar una demanda de cobro. Es importante tener documentación sobre los pagos atrasados. En casos extremos, se puede solicitar hasta el apremio corporal. ¡Tranqui, que nosotros le echamos una mano con todo el proceso!",
        keywords: ["pensión", "alimentaria", "ex", "cobro", "demanda"]
      },
      {
        question: "¿Qué es la patria potestad y cómo se puede perder?",
        answer: "La patria potestad es el conjunto de derechos y deberes que tienen los padres sobre sus hijos menores de edad. Se puede perder por abuso, abandono, incumplimiento de deberes parentales, o por sentencia judicial. La pérdida de la patria potestad no exime de las obligaciones económicas hacia los hijos.",
        keywords: ["patria", "potestad", "perder", "derechos", "deberes", "padres", "hijos"]
      },
      {
        question: "¿Qué es el régimen de visitas y cómo se establece?",
        answer: "El régimen de visitas es el derecho-deber de mantener contacto y comunicación entre el padre o madre que no tiene la custodia y sus hijos. Se establece por acuerdo entre los padres o por decisión judicial. Debe considerar el interés superior del menor y puede incluir visitas, llamadas telefónicas, y vacaciones compartidas.",
        keywords: ["régimen", "visitas", "custodia", "contacto", "comunicación", "padres", "hijos"]
      }
    ]
  },
  {
    category: "Derecho Inmobiliario",
    questions: [
      {
        question: "¿Qué debo revisar antes de comprar una propiedad?",
        answer: "Antes de comprar una propiedad, es importante verificar el estudio registral, planos catastrados, impuestos al día, permisos de construcción, y posibles servidumbres o gravámenes. También es recomendable realizar una inspección física de la propiedad.",
        keywords: ["comprar", "propiedad", "estudio registral", "planos", "impuestos", "permisos"]
      },
      {
        question: "¿Qué es un contrato de arrendamiento y qué debe incluir?",
        answer: "Un contrato de arrendamiento es un acuerdo entre el propietario y el inquilino para el uso de una propiedad. Debe incluir la identificación de las partes, descripción de la propiedad, duración del contrato, monto del alquiler, forma de pago, depósito de garantía, y las responsabilidades de cada parte.",
        keywords: ["arrendamiento", "alquiler", "contrato", "inquilino", "propietario"]
      },
      {
        question: "¿Qué es una servidumbre y cómo afecta a una propiedad?",
        answer: "Una servidumbre es un derecho real que limita el dominio de un predio (sirviente) en beneficio de otro (dominante). Puede afectar el uso y valor de una propiedad, por ejemplo, permitiendo el paso por ella. Es importante verificar la existencia de servidumbres antes de comprar una propiedad.",
        keywords: ["servidumbre", "limitación", "propiedad", "derecho real"]
      },
      {
        question: "¿Qué es el usufructo y cómo funciona?",
        answer: "El usufructo es un derecho real que permite a una persona usar y disfrutar de un bien ajeno, con la obligación de conservarlo. El usufructuario puede utilizar la propiedad y percibir sus frutos, pero no puede venderla. Es común en herencias y puede ser vitalicio o por un tiempo determinado.",
        keywords: ["usufructo", "derecho real", "uso", "disfrute", "propiedad"]
      }
    ]
  },
  {
    category: "Derecho Ambiental",
    questions: [
      {
        question: "¿Qué es una evaluación de impacto ambiental y cuándo se requiere?",
        answer: "Una evaluación de impacto ambiental es un estudio técnico que analiza los efectos de un proyecto sobre el medio ambiente. Se requiere para proyectos de construcción, actividades productivas o extractivas que puedan afectar significativamente el ambiente. La SETENA es la entidad encargada de aprobar estos estudios.",
        keywords: ["impacto ambiental", "evaluación", "SETENA", "proyecto", "construcción"]
      },
      {
        question: "¿Cuáles son las sanciones por tala ilegal de árboles?",
        answer: "Las sanciones por tala ilegal pueden incluir multas, prisión de tres meses a tres años, y la obligación de reforestar. La severidad depende de factores como la cantidad de árboles talados, si estaban en áreas protegidas, y si hubo daño a fuentes de agua.",
        keywords: ["tala", "ilegal", "árboles", "sanciones", "reforestar"]
      }
    ]
  },
  {
    category: "Derecho del Consumidor",
    questions: [
      {
        question: "¿Qué puedo hacer si un producto que compré está defectuoso?",
        answer: "Si un producto está defectuoso, tiene derecho a la reparación gratuita, reposición del producto, o devolución del dinero. Primero, contacte al vendedor o fabricante. Si no responden satisfactoriamente, puede presentar una denuncia ante la Comisión Nacional del Consumidor.",
        keywords: ["defectuoso", "producto", "garantía", "denuncia", "consumidor"]
      },
      {
        question: "¿Cuáles son mis derechos si un vuelo se cancela o retrasa?",
        answer: "En caso de cancelación o retraso significativo, la aerolínea debe ofrecer asistencia (comidas, alojamiento si es necesario), reembolso completo o transporte alternativo. En algunos casos, también puede tener derecho a una compensación adicional. Es importante conocer las políticas de la aerolínea y las regulaciones aplicables.",
        keywords: ["vuelo", "cancelación", "retraso", "derechos", "aerolínea"]
      }
    ]
  },
  {
    category: "Derecho Tributario",
    questions: [
      {
        question: "¿Qué es el impuesto sobre la renta y quién debe pagarlo?",
        answer: "El impuesto sobre la renta es un tributo que grava los ingresos obtenidos por personas físicas y jurídicas. En Costa Rica, deben pagarlo las personas y empresas que generen ingresos en el país. La tasa varía según el nivel de ingresos para personas físicas, y es fija para las jurídicas.",
        keywords: ["impuesto", "renta", "tributo", "ingresos", "personas físicas", "personas jurídicas"]
      },
      {
        question: "¿Cómo funciona el IVA en Costa Rica?",
        answer: "El Impuesto al Valor Agregado (IVA) en Costa Rica es del 13% para la mayoría de bienes y servicios. Algunos productos de la canasta básica, medicamentos, y servicios educativos están exentos o tienen una tasa reducida. Los negocios deben declarar y pagar el IVA mensualmente.",
        keywords: ["IVA", "impuesto", "valor agregado", "tasa", "declaración"]
      },
      {
        question: "¿Qué son las deducciones fiscales y cómo puedo aprovecharlas?",
        answer: "Las deducciones fiscales son gastos que se pueden restar de los ingresos antes de calcular el impuesto a pagar. En Costa Rica, algunas deducciones comunes incluyen gastos médicos, educativos, alquileres, y donaciones a organizaciones autorizadas. Es importante guardar los comprobantes para justificar estas deducciones.",
        keywords: ["deducciones", "fiscales", "gastos", "impuestos", "comprobantes"]
      },
      {
        question: "¿Qué consecuencias tiene no presentar la declaración de impuestos a tiempo?",
        answer: "No presentar la declaración de impuestos a tiempo puede resultar en multas, intereses por mora, y en casos graves, en sanciones penales. Las multas varían según el impuesto y el tiempo de retraso. Es crucial cumplir con los plazos establecidos para evitar estas consecuencias.",
        keywords: ["declaración", "impuestos", "multas", "intereses", "sanciones", "retraso"]
      }
    ]
  }
];

export default commonLegalQuestions;