import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { typography } from '../styles/sharedStyles';
import logo from '../logo.png';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { darkMode, toggleTheme } = useTheme();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    if (darkMode) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [darkMode]);

  const headerBg = darkMode ? 'bg-gray-900/70' : 'bg-white/70';
  const textColor = darkMode ? 'text-gray-100' : 'text-gray-800';
  const hoverColor = darkMode ? 'hover:text-white' : 'hover:text-gray-600';
  const buttonBg = darkMode ? 'bg-gray-700/50 hover:bg-gray-600/50' : 'bg-gray-200/50 hover:bg-gray-300/50';
  const mobileMenuBg = darkMode ? 'bg-gray-800/90' : 'bg-gray-100/90';

  return (
    <>
      {darkMode && (
        <div
          className="light-effect"
          style={{
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
          }}
        />
      )}
      <motion.header
        className={`${headerBg} ${typography.sans} ${textColor} backdrop-blur-md backdrop-saturate-150 fixed top-0 left-0 right-0 z-[10000] transition-colors duration-300`}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img 
                src={logo} 
                alt="Bufete Salas Granados" 
                className="h-12 w-auto scale-[5.5] transform translate-y-2" 
              /> {/* Changed scale to 5.5 */}
            </Link>
            <div className="flex items-center space-x-6">
              <nav className="hidden md:flex space-x-8">
                {['Inicio', 'Servicios', 'Sobre Nosotros', 'Contacto'].map((item) => (
                  <Link
                    key={item}
                    to={item === 'Inicio' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                    className={`${textColor} ${hoverColor} transition duration-300`}
                  >
                    {item}
                  </Link>
                ))}
              </nav>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={toggleTheme}
                className={`p-2 rounded-full ${buttonBg} ${textColor} backdrop-blur-sm transition-colors duration-300`}
                aria-label={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
              >
                {darkMode ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                )}
              </motion.button>
              <motion.button
                className="md:hidden focus:outline-none"
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
              >
                <svg className={`w-6 h-6 ${textColor}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </motion.button>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <motion.div
            className={`md:hidden ${mobileMenuBg} backdrop-blur-md`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <nav className="px-4 pt-2 pb-4 space-y-2">
              {['Inicio', 'Servicios', 'Sobre Nosotros', 'Contacto'].map((item) => (
                <motion.div
                  key={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link
                    to={item === 'Inicio' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`}
                    className={`block ${hoverColor} px-3 py-2 rounded transition duration-300 ${textColor}`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item}
                  </Link>
                </motion.div>
              ))}
            </nav>
          </motion.div>
        )}
      </motion.header>
    </>
  );
}

export default Header;