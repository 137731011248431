import React from "react";
import "../ChatbotStyles.css";

const serviciosCategories = [
  { id: "procesosCiviles", text: "Procesos Civiles" },
  { id: "procesosLaborales", text: "Procesos Laborales" },
  { id: "procesosPenales", text: "Procesos Penales" },
  { id: "serviciosNotariales", text: "Servicios Notariales" },
  { id: "asuntosAdministrativos", text: "Asuntos Administrativos" },
  { id: "derechoFamiliar", text: "Derecho Familiar" },
];

const ServiciosOptions = ({ actionProvider }) => {
  const handleCategoriaClick = (categoriaId) => {
    actionProvider.handleCategoriaSeleccionada(categoriaId);
  };

  return (
    <div className="servicios-options-container">
      {serviciosCategories.map((categoria) => (
        <button
          key={categoria.id}
          className="servicio-option"
          onClick={() => handleCategoriaClick(categoria.id)}
        >
          {categoria.text}
        </button>
      ))}
    </div>
  );
};

export default ServiciosOptions;