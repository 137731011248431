import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />
        <title>My App</title>
      </head>
      <body>
        <header>
          <nav>
            {/* Your navigation code here */}
          </nav>
        </header>
        <main>
          <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
            {children}
          </ThemeContext.Provider>
        </main>
        <footer>
          {/* Your footer code here */}
        </footer>
      </body>
    </>
  );
};