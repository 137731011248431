import commonLegalQuestions from './commonLegalQuestions';

const keywordMapping = commonLegalQuestions.flatMap(category => 
  category.questions.map(q => ({
    category: category.category,
    question: q.question,
    answer: q.answer,
    keywords: q.keywords
  }))
);

export default keywordMapping;